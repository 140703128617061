import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import classNames from "classnames";

import { ButtonLeft, ButtonRight } from "./buttons";

function Item({ onClick, imageUrl, thumbnailUrl }) {
  return (
    <div className="py-4 px-3 w-full md:w-1/4">
      <div className="h-full rounded-lg overflow-hidden">
        <div className="cursor-pointer aspect-w-3 aspect-h-2">
          <img
            onClick={() => onClick(imageUrl)}
            className="object-cover"
            src={thumbnailUrl}
            alt="image"
          />
        </div>
      </div>
    </div>
  );
}

function Modal({ modal, onCloseClick }) {
  const classes = classNames(
    {
      hidden: modal.hidden
    },
    "fixed",
    "z-50",
    "overflow-auto",
    "inset-0",
    "bg-gray-300",
    "bg-opacity-70",
    "flex"
  );
  return (
    <div className={classes}>
      <div className="relative w-full max-w-screen-lg m-auto flex-col flex">
        <span
          onClick={onCloseClick}
          className="absolute -top-9 right-0 w-8 text-center text-4xl font-bold cursor-pointer hover:text-gray-400"
        >
          &times;
        </span>
        <div className="border-8 border-gray-300 border-opacity-80saspect-w-3 aspect-h-2">
          <img className="object-cover" src={modal.imageUrl} alt="image" />
        </div>
      </div>
    </div>
  );
}

function Photos() {
  const [modal, setModal] = useState({
    imageUrl: "",
    hidden: true
  });
  const [photosItems, setPhotosItems] = useState([]);
  const [page, setPage] = useState({
    offset: 0,
    limit: 4
  });

  useEffect(() => {
    setPhotosItems(window.whlotos.photos);
  }, []);

  useEffect(() => {
    axios.get("/api/photos/", { params: { ...page } }).then(response => {
      setPhotosItems(response.data);
    });
  }, [page]);

  const clickButtonLeft = () => {
    if (page.offset !== 0) {
      setPage({ ...page, offset: page.offset - 1 });
    }
  };

  const clickButtonRight = () => {
    setPage({ ...page, offset: page.offset + 1 });
  };

  const onPhotoClick = imageUrl => {
    console.log("onPhotoClick", imageUrl);
    setModal({ imageUrl: imageUrl, hidden: false });
  };

  const onCloseClick = () => {
    setModal({ imageUrl: "", hidden: true });
  };

  return (
    <React.Fragment>
      <div className="tw-container-empty mx-auto my-4">
        <div className="m-2 flex flex-wrap items-center justify-between">
          <h1 className="mb-2 uppercase text-gray-700 text-xl title-font font-semibold">
            Фотогалерея
          </h1>
          <div>
            <ButtonLeft onClick={clickButtonLeft} />
            <ButtonRight onClick={clickButtonRight} />
          </div>
        </div>
        <div className="flex flex-wrap">
          {photosItems.slice(0, page.limit).map(photo => {
            return (
              <Item
                key={photo.imageUrl}
                onClick={onPhotoClick}
                imageUrl={photo.imageUrl}
                thumbnailUrl={photo.thumbnailUrl}
              />
            );
          })}
        </div>
      </div>
      <Modal modal={modal} onCloseClick={onCloseClick} />
    </React.Fragment>
  );
}

ReactDOM.render(<Photos />, document.getElementById("react-photos"));
